import React, { useEffect } from 'react'
import '../pages/lea.css.less'

let TxtType = function(el, toRotate, period) {
	console.log(TxtType.toRotate)
	this.toRotate = toRotate
	this.el = el
	this.loopNum = 0
	this.period = parseInt(period, 10) || 2000
	this.txt = ''
	this.tick()
	this.isDeleting = false
}

TxtType.prototype.tick = function() {
	var i = this.loopNum % this.toRotate.length
	var fullTxt = this.toRotate[i]

	if (this.isDeleting) {
		this.txt = fullTxt.substring(0, this.txt.length - 20)
	} else {
		this.txt = fullTxt.substring(0, this.txt.length + 5)
	}

	this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>'

	var that = this
	var delta = 200 - Math.random() * 100

	if (this.isDeleting) {
		delta /= 2
	}

	if (!this.isDeleting && this.txt === fullTxt) {
		delta = this.period
		this.isDeleting = true
	} else if (this.isDeleting && this.txt === '') {
		this.isDeleting = false
		this.loopNum++
		delta = 500
	}

	setTimeout(() => {
		that.tick()
	}, delta)
}

const Leatexteffect = () => {
	useEffect(() => {
		var elements = document.getElementsByClassName('typewrite')
		for (var i = 0; i < elements.length; i++) {
			const toRotate = elements[i].getAttribute('data-type')
			const period = elements[i].getAttribute('data-period')
			if (toRotate) {
				new TxtType(elements[i], JSON.parse(toRotate), period)
			}
		}
	}, [])

	return (
		<div className="lea-question-content">
			<h1>
				<div
					className="typewrite"
					data-period="2000"
					data-type='[
						"What is the difference between annulment and legal separation?",
						"What are an employee&#39;s leave benefits?",
						"What is the tax treatment of transportation allowance?",
						"Can you provide a deed of sale with an assumption of mortgage?",
						"Can you provide a summary of Republic vs. Sereno?",
						"Can you provide the full text of Medina vs. Guinto, I can`t seem to find it in the internet?"
					]'
				>
					<span className="wrap"></span>
				</div>
			</h1>
		</div>
	)
}

export default Leatexteffect
