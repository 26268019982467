import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import './lea.css.less'
import leaHead from '../images/lea_logo.png'
import Leatexteffect from '../components/leatexteffect.js'
import LeaText from '../images/lea_blue.png'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const Lea = () => {
	const data = useStaticQuery(graphql`
		query Image2 {
			image2: file(relativePath: { eq: "cplea.png" }) {
				id
				childImageSharp {
					fixed(width: 600) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)
	return (
		<Layout>
			<Seo title="Ask LEA" />
			<header className="lea-container">
				<div className="ui mobile reversed stackable grid">
					<div className="eight wide column">
						<Img
							fixed={data.image2.childImageSharp.fixed}
							className="lea-cp-image"
						/>
					</div>
					<div className="eight wide column">
						<div className="lea-question-container">
							<h1 className="lea-title">
								Ask
								<img src={LeaText} alt="" />
							</h1>
							<Leatexteffect />
						</div>
						<div className="lea-description">
							<img src={leaHead} alt="" className="lea-head" />
							<p>
								<h4 className="text-highlight">
									<img src={LeaText} alt="" style={{ height: '1em' }} /> stands
									for{' '}
									<b>
										legal <br />
										engineer assistant.
									</b>
								</h4>
								<span style={{ color: 'dimgray' }}>
									She is your personal legal assistant <br />
									who helps in doing legal topic
									<br /> research, case summaries and <br />
									drafting of legal and business form <br />
									templates.
								</span>
							</p>
						</div>
					</div>
				</div>
			</header>
		</Layout>
	)
}

export default Lea
